import * as THREE from 'three';
import GUI from 'lil-gui';
import { initMediaPipe } from './initMediaPipe';
import { threeFaceMesh } from './facemesh';
import { createGUIButton } from './createGUIButton';

export function createVideoElement(): HTMLVideoElement {
  const videoEl = document.createElement('video');
  videoEl.style.display = 'none';
  videoEl.autoplay = true;
  videoEl.playsInline = true;
  document.body.append(videoEl);
  return videoEl;
}

async function createVideoPanel() {
  // const ms = await navigator.mediaDevices.getUserMedia({video: { width: 640, height: 320 }, audio: false});
  const ms = await navigator.mediaDevices.getUserMedia({video: { width: 1280, height: 720 }, audio: false});
  const videoEl = createVideoElement();
  videoEl.srcObject = ms;
  const videoTrack = ms.getVideoTracks()[0];
  const { width, height } = videoTrack.getSettings();

  const videoReadyPromise = new Promise(resolve => {
    videoEl.onloadedmetadata = () => {
      // video is ready to use with facemesh.
      console.log('video Ready')
      resolve(videoEl);
    }
  });
  var geometry = new THREE.PlaneGeometry(4, 4);
  const texture = new THREE.VideoTexture(videoEl);
  // const texture = null;
  const YELLOW = 0xffff00;
  var material = new THREE.MeshBasicMaterial({ color: YELLOW, side: THREE.DoubleSide, map: texture });
  var plane = new THREE.Mesh(geometry, material);
  const video = await videoReadyPromise;
  return { videoEl, plane, width, height };
}

export async function FaceMaker (scene: THREE.Scene, gui: GUI) {
  const { videoEl, plane, width, height } = await createVideoPanel();
  const { faceMesh, resultHandler } = threeFaceMesh({ gui, width, height });
  const mediaPipe = initMediaPipe(gui, resultHandler);

  const faceMakerOptions = {
    sendToFaceMesh : false,
    plane: false,
  };

  if (faceMakerOptions.plane) {
    scene.add(plane);
  };

  scene.add(faceMesh);

  // gui.add(faceMakerOptions, 'plane').onChange(() => {
  //   if (faceMakerOptions.plane) {
  //     scene.add(plane);
  //   } else {
  //     scene.remove(plane);
  //   }
  // });

  const startButton = createGUIButton(gui, "start", () => {
    faceMakerOptions.sendToFaceMesh = true;
    updateFaceMesh();
    videoEl.play();
    startButton.disable();
    stopButton.enable();
  });

  const stopButton = createGUIButton(gui, "stop", () => {
    faceMakerOptions.sendToFaceMesh = false;
    startButton.enable();
    stopButton.disable();
  });

  if (faceMakerOptions.sendToFaceMesh) {
    startButton.disable();
    stopButton.enable();
  } else {
    startButton.enable();
    stopButton.disable();
  }


  const updateFaceMesh = async () =>  {
    await mediaPipe.send({ image: videoEl });
    if (faceMakerOptions.sendToFaceMesh) {
      requestAnimationFrame(() => updateFaceMesh());
    }
  }

  return { faceMesh };

}
