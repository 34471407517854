import * as THREE from 'three';
import { FaceGeometry, FACEMESH_LEFT_EYE, FACEMESH_RIGHT_EYE, Results } from '@mediapipe/face_mesh';
import {
  BufferAttribute,
  BufferGeometry,
  InterleavedBuffer,
  InterleavedBufferAttribute,
  MeshStandardMaterial,
  Vector3,
} from 'three';
import { FACES, UVS as texCoords } from './geometry.js';
import GUI from 'lil-gui';

export function createCanvasElement(): HTMLCanvasElement {
  const el = document.createElement('canvas');
  return el;
}

interface OffscreenCanvasRenderingContext2D
  extends CanvasState,
    CanvasTransform,
    CanvasCompositing,
    CanvasImageSmoothing,
    CanvasFillStrokeStyles,
    CanvasShadowStyles,
    CanvasFilters,
    CanvasRect,
    CanvasDrawPath,
    CanvasUserInterface,
    CanvasText,
    CanvasDrawImage,
    CanvasImageData,
    CanvasPathDrawingStyles,
    CanvasTextDrawingStyles,
    CanvasPath {
  readonly canvas: OffscreenCanvas;
}
declare var OffscreenCanvasRenderingContext2D: {
  prototype: OffscreenCanvasRenderingContext2D;
  new (): OffscreenCanvasRenderingContext2D;
};

export declare type OffscreenCanvas = any;

export type FaceMakerOptions = {
  useHolistic: boolean,
  sendToFashMesh: boolean;
  enableFaceGeometry: boolean;
};

// option required only for ThreeFaceMesh
type FaceMeshOptions = {
  useVideoTexture: boolean;
  useWireFrame: boolean;
  useReference: boolean;
  updateTexture: boolean;
}

  // we create a canvas/context and texture here.
  // late the canvas is updated by updateTexture
function makeTexture({ width, height }) {
  const canvas = createCanvasElement();
  // TODO: this works but need to be made dynamic.
  canvas.width = width;
  canvas.height = height;

  const canvasContext = canvas.getContext('2d');
  const canvasTexture = canvasContext ? new THREE.CanvasTexture(canvasContext.canvas) : null;
  return { canvas, canvasContext, canvasTexture };
}

function createGeometryAndResultHandler(options: FaceMeshOptions, updateTexture: (inputFrameBuffer: OffscreenCanvas) => void ) {
  const geometry = new THREE.BufferGeometry();
  const positions = new Float32Array(468 * 3);
  const positionAttribute = new BufferAttribute(positions, 3);
  positionAttribute.setUsage(THREE.DynamicDrawUsage);
  geometry.setAttribute('position', positionAttribute);

  const uvs = new Float32Array(468 * 2);
  const UVAttribute = new BufferAttribute(uvs, 2);
  geometry.setAttribute('uv', UVAttribute);

  // FACES is an array of 2640 ( 2640/3 =880)
  // elements with each three making a face
  // console.log('FACES.length = ', FACES.length); //
  geometry.setIndex(FACES);
  geometry.computeVertexNormals();

  // when using reference, we will keep nose position glued to specifc.
  let desiredNosePosition: Vector3 | null = null;
  let correction = new Vector3(0,0,0);
  // results handler
  const resultHandler = (results: Results) => {
    const faces = results.multiFaceLandmarks;

    if (faces.length > 0 && geometry) {
      const face = faces[0];
      // console.log('FACEMESH_RIGHT_EYE: ', FACEMESH_RIGHT_EYE);
      const rightEyeIndexes = FACEMESH_RIGHT_EYE.flat();
      const leftEyeIndexes = FACEMESH_LEFT_EYE.flat();


      // flip the image on upside down.
      face.forEach((p, i) => {
        // for our face y is flipped (upside down)
        // for example nose is above eyes.
        //    eye.y = 0.4
        //    nose.y = 0.8

        // to fix it:
        p.y = -p.y + 1;

        // so after this we get
        //  eye.y = 0.6
        //  nose.y = 0.2
        //  making nose below eyes.
      });

      const REFERENCE_POSITION = 4; // NOSE
      const currentNosePosition = face[REFERENCE_POSITION];
      // console.log(`length = ${face.length}, reference: ${refPos.x}, ${refPos.y}, ${refPos.z}`);
      face.forEach((p, i) => {
        if (i >= 468) {
          // ignore iris.
          return;
        }

        if (options.useReference) {

          if (!desiredNosePosition) {
            // when useReference is toggled, we remember the nose position.
            desiredNosePosition = new Vector3(currentNosePosition.x, currentNosePosition.y, currentNosePosition.z);
          }

          // and we find correction between original nose and current nose position
          correction.set(desiredNosePosition.x-currentNosePosition.x, desiredNosePosition.y-currentNosePosition.y, desiredNosePosition.z-currentNosePosition.z);
        } else {
          desiredNosePosition = null;
          correction.set(0,0,0);
        }

        const pStart = i * 3;
        positions[pStart] = p.x + correction.x;
        positions[pStart + 1] = p.y + correction.y;
        positions[pStart + 2] = p.z + correction.z;
        positionAttribute.needsUpdate = true;

        // update UV map only if we are updating texture.
        if (options.updateTexture) {
          updateTexture(results.image);
          const uvStart = i * 2;
          uvs[uvStart] = p.x;
          uvs[uvStart + 1] = p.y;
          UVAttribute.needsUpdate = true;
        }
      });

      geometry.computeVertexNormals();
    }
  };

  return { geometry, resultHandler };
}

function createMesh(geometry: BufferGeometry, texture: THREE.Texture | null) {
  const faceMaterial = new THREE.MeshStandardMaterial({
    color: 'white',
    roughness: 0.4,
    metalness: 0.1,
    side: THREE.DoubleSide,
    map: texture,
  });
  const faceMesh = new THREE.Mesh(geometry, faceMaterial);

  const wireMaterial = new THREE.MeshBasicMaterial({
    color: 'black',
    wireframe: true,
    transparent: true,
    opacity: 0.3,
  });

  const wireMesh = new THREE.Mesh(geometry, wireMaterial);
  // without this the the face disappears if camera moved closer.
  // https://stackoverflow.com/questions/32855271/three-js-buffergeometry-disappears-after-moving-camera-to-close/32876611
  faceMesh.frustumCulled = false;
  wireMesh.frustumCulled = false;
  return { faceMesh, wireMesh };
}


// returns a mesh of face.
export function threeFaceMesh({ gui, width, height }: { gui: GUI, width: number, height: number }) {
    const group = new THREE.Group();
    const meshOptions = {
      useVideoTexture: true,
      useWireFrame: true,
      useReference: false,
      updateTexture: true,
    };

    // create a canvas to update texture.
    const { canvas, canvasContext, canvasTexture } = makeTexture({ width, height });

    const updateTexture = (inputFrameBuffer: OffscreenCanvas) => {
      if (canvasContext && meshOptions.updateTexture) {
        canvasContext.fillStyle = '#FFF';
        if (
          canvasContext.canvas.width !== inputFrameBuffer.width ||
          canvasContext.canvas.height !== inputFrameBuffer.height
        ) {
          console.warn(
            `size mismatch inputFrameBuffer=${inputFrameBuffer.width}x${inputFrameBuffer.height} canvas=${canvasContext.canvas.width}x${canvasContext.canvas.height}`
          );
        } else {
          canvasContext.drawImage(inputFrameBuffer, 0, 0, inputFrameBuffer.width, inputFrameBuffer.height);
          if (canvasTexture) {
            canvasTexture.needsUpdate = true;
          }
        }
      }
    }

    const { geometry, resultHandler } = createGeometryAndResultHandler(meshOptions, updateTexture);

    const texture = meshOptions.useVideoTexture ? canvasTexture : null;
    const { faceMesh, wireMesh } = createMesh(geometry, texture);

    group.add(faceMesh);
    if (meshOptions.useWireFrame) {
      group.add(wireMesh);
    }
    group.applyMatrix4(new THREE.Matrix4().makeScale(5, 5, 5));

    // gui = gui.addFolder('Mesh');
    gui.add(meshOptions, 'useVideoTexture').onChange(() => {
      const material = faceMesh.material as MeshStandardMaterial;
      material.map = meshOptions.useVideoTexture ? canvasTexture : null;
      material.needsUpdate = true;
    });
    gui.add(meshOptions, 'useWireFrame').onChange(() => {
      meshOptions.useWireFrame ? group.add(wireMesh) : group.remove(wireMesh);
    });
    // gui.add(meshOptions, 'useReference');
    // gui.add(meshOptions, 'updateTexture');
    return { resultHandler, faceMesh:group };
}
