// add styles
import './style.css';
// three.js
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import GUI from 'lil-gui';
import { FaceMaker } from './facemaker';

async function init() {

  // @ts-ignore
  const gui = new GUI({ width: 300, touchStyles: false });
  gui.domElement.classList.add( 'force-touch-styles' );

  // const folder = gui.addFolder("Top Options");

  const options = {
    animate: true,
    box: true,
  };
  // folder.add(options, 'animate');


  // create the scene
  const scene = new THREE.Scene();

  // create the camera
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);


  const renderer = new THREE.WebGLRenderer();
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
  renderer.setPixelRatio(DPR);

  // renderer.toneMapping = true;

  // set size
  renderer.setSize(window.innerWidth, window.innerHeight);

  // add canvas to dom
  document.body.appendChild(renderer.domElement);
  window.addEventListener('resize', () => {
    const { innerWidth, innerHeight } = window;
    console.log({ innerWidth, innerHeight });
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.aspect = innerWidth / innerHeight;
    camera.updateProjectionMatrix();
  });

  // add axis to the scene
  const axis = new THREE.AxesHelper(10);

  scene.add(axis);

  // add lights
  // const light = new THREE.DirectionalLight(0xffffff, 1.0);

  // //light.position.set(100, 100, 100);

  // scene.add(light);

  // const light2 = new THREE.DirectionalLight(0xffffff, 1.0);

  // light2.position.set(-100, 100, -100);

  // scene.add(light2);

  const hemiLight = new THREE.HemisphereLight(0xddeeff, 0x0f0e0d, 0.02);
  hemiLight.intensity = 3; // 30 looks good with renderer.toneMapping

  scene.add(hemiLight);

  const material = new THREE.MeshBasicMaterial({
    color: 0xaaaaaa,
    wireframe: true,
  });

  // create a box and add it to the scene
  const box = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), material);
  box.position.x = 0.5;
  box.rotation.y = 0.5;

  if (options.box) {
    scene.add(box);
  }
  // folder.add(options, 'box').onChange(() => {
  //   if (options.box) {
  //     scene.add(box);
  //   } else {
  //     scene.remove(box);
  //   }
  // });

  const { faceMesh } = await FaceMaker(scene, gui);


  const orbitControls = new OrbitControls(camera, renderer.domElement);
  // const controllers = [];
  // controllers.push(gui.add(orbitControls.target, 'x', -10,10,0.1).onChange(() => orbitControls.update()))
  // controllers.push(gui.add(orbitControls.target, 'y', -10,10,0.1).onChange(() => orbitControls.update()));
  // controllers.push(gui.add(orbitControls.target, 'z', -10,10,0.1).onChange(() => orbitControls.update()));
  // controllers.push(gui.add(camera.position, 'x', -10,10,0.1).onChange(() => orbitControls.update()));
  // controllers.push(gui.add(camera.position, 'y', -10,10,0.1).onChange(() => orbitControls.update()));
  // controllers.push(gui.add(camera.position, 'z', -10,10,0.1).onChange(() => orbitControls.update()));
  // orbitControls.addEventListener( 'change', () => controllers.forEach(c => c.updateDisplay()));
  camera.position.set(3, 2, -6);
  orbitControls.target.set(3, 4.5, 1.8);
  orbitControls.update();

  // @ts-ignore
  window.camera = camera;
  // @ts-ignore
  window.orbitControls = orbitControls;

  function animate(): void {
    requestAnimationFrame(animate);
    render();
  }

  function render(): void {
    const timer = 0.002 * Date.now();
    box.position.y = 0.5 + 0.5 * Math.sin(timer);
    box.rotation.x += 0.1;
    renderer.render(scene, camera);
  }

  animate();
};

init();

