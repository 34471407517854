import { FaceMesh, Results as FaceMeshResults } from '@mediapipe/face_mesh';
import GUI from 'lil-gui';

export function initMediaPipe(gui: GUI, processResults: (results: FaceMeshResults) => void): FaceMesh {
  const faceMesh = new FaceMesh({
    locateFile: file => {
      return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
    },
  });

  const faceMeshOptions = {
    enableFaceGeometry: false,
    refineLandmarks: true,
    selfieMode: true,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5,
    maxNumFaces: 1,
  };

  faceMesh.setOptions(faceMeshOptions);
  console.warn('initializing facemesh');

  faceMesh.initialize().then(() => {
    console.log('face mesh initialized');
    faceMesh.onResults(processResults);
  });

  const optionsChanged = () => {
    faceMesh.setOptions(faceMeshOptions);
  };

  // gui.add(faceMeshOptions, 'enableFaceGeometry').onChange(optionsChanged);
  // const faceMeshOptionsGui = gui.addFolder('faceMeshOptions').onChange(optionsChanged);
  // faceMeshOptionsGui.add(faceMeshOptions, 'refineLandmarks').onChange(optionsChanged);
  // faceMeshOptionsGui.add(faceMeshOptions, 'selfieMode').onChange(optionsChanged);
  // faceMeshOptionsGui.add(faceMeshOptions, 'minDetectionConfidence').onChange(optionsChanged);
  // faceMeshOptionsGui.add(faceMeshOptions, 'minTrackingConfidence').onChange(optionsChanged);

  // enableFaceGeometry can be changed only before creating the mesh.
  // faceMeshOptionsGui.onChange(event => {
  //   if (event.property === 'enableFaceGeometry') {
  //     faceMeshOptions.enableFaceGeometry = event.value;
  //     if (faceMeshOptions.enableFaceGeometry) {
  //       faceMeshOptions.refineLandmarks = false;
  //     }
  //   }
  // });

  return faceMesh;
}
